import { type AppType } from "next/app";
import { api } from "~/utils/api";
import "~/core/styles/globals.css";
import { ThemeProvider } from "next-themes";
import { SubscriptionProvider } from "use-stripe-subscription";
import { ClerkProvider } from "@clerk/nextjs";
import { PrismicPreview } from "@prismicio/next";
import { PrismicProvider } from "@prismicio/react";
import Link from "next/link";
import { repositoryName } from "prismicio";
import { useRouter } from "next/router";
import Head from "next/head";

const MyApp: AppType = ({ Component, pageProps: { ...pageProps } }) => {
  const router = useRouter();

  const isEmbedPage =
    router.pathname.startsWith("/embed/") ||
    router.pathname.startsWith("/embedTest/") ||
    router.pathname.startsWith("/embedTest2/") ||
    router.pathname.startsWith("/graph");

  if (isEmbedPage) {
    return (
      <>
        <ThemeProvider attribute="class" enableSystem forcedTheme="light">
          <Component {...pageProps} />
        </ThemeProvider>
      </>
    );
  }

  return (
    <>
      <SubscriptionProvider
        stripePublishableKey={
          process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY as string
        }
      >
        <ClerkProvider {...pageProps}>
          <Head>
            <title>InstantCharts</title>
            <meta
              name="description"
              content="The ultimate tool create and embed charts in Notion"
            />
            <link rel="icon" href="/favicon.ico" />
          </Head>
          <ThemeProvider
            attribute="class"
            enableSystem
            forcedTheme={isEmbedPage ? "light" : undefined}
          >
            <PrismicProvider
              internalLinkComponent={(props) => <Link {...props} />}
            >
              <PrismicPreview repositoryName={repositoryName}>
                <Component {...pageProps} />
              </PrismicPreview>
            </PrismicProvider>
          </ThemeProvider>
        </ClerkProvider>
      </SubscriptionProvider>
    </>
  );
};

export default api.withTRPC(MyApp);
